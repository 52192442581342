@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');
/*.overlay{
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: red;
}*/
.align-items-center {
    align-items: center!important;
}
.justify-content-center {
    justify-content: center!important;
}
.d-flex {
    display: flex!important;
}
.overlay{
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: #1a1a1a;
}


.sidebar-wrapper{
  top: 50px;
}


.smoke span {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    color: white;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
    font-family: 'Finger Paint', cursive;
}
.bg-transparent{
  background-color: transparent !important;
}
.p-absolute{
  position: absolute;
}
.opacity{
  background-color: rgba(0, 0, 0, 0.5);
}
.logo{
 font-size: 35px;
    font-weight: 900;
    font-family: 'Finger Paint', cursive;
    color: #000; 
    text-decoration: none;
    padding-top: 10px;
}

.smoke span {
  display: inline-block;
  text-shadow: 0 0 0 whitesmoke;
  animation: smoky 5s 3s both;
}

.smoke span:nth-child(even){
  animation-name: smoky-mirror;
}

@keyframes smoky {
  60% {
    text-shadow: 0 0 40px whitesmoke;
  }
  to {
    transform:
      translate3d(15rem,-8rem,0)
      rotate(-40deg)
      skewX(70deg)
      scale(1.5);
    text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}

@keyframes smoky-mirror {
  60% {
    text-shadow: 0 0 40px whitesmoke; }
  to {
    transform:
      translate3d(18rem,-8rem,0)
      rotate(-40deg) 
      skewX(-70deg)
      scale(2);
     text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}


.modal-backdrop {
   background-color: transparent;
}
.card-body{
	font-family: 'Roboto Mono', monospace;
}
.modal-logo{
  font-family: 'Finger Paint', cursive;
  color: #fff;
  font-size: 25px;
}
.card-body p{
	font-size: 14px;
}
.footer-link{
	list-style: none;
}
.card{
	    box-shadow: 8px 8px 0 0 #222326;
    border: 1px solid #222326;
    border-radius: 10px;
    border-radius: 5px;
    background: #fff
}

.cst_transparent{opacity:  0.85;}
.cst_transparent_home{opacity:  0.75;}

#countdown {
  position: relative;
  margin: 0px .5rem;
  height: 20px;
  width: 20px;
  text-align: center;
  float: left;
}
#countdown-number {
  color: #000;
  display: inline-block;
  line-height: 20px;
  font-weight: 700;
  font-size: 12px;
}
#countdown svg {
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 20px;
  overflow: visible;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
  stroke-dasharray: 50px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #000;
  fill: none;
}

.btn-transparent{
  background-color: transparent;
  border: none;
}
.btn-transparent i{
  font-size: 20px;
  font-weight: 100;
}


#myVideo {margin-bottom:  10px !important;}

#wavybg-wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
}
.custom-switch label{
  vertical-align: text-top;
}
.tool-tip {
  display: inline-block;
}

.tool-tip [disabled] {
  pointer-events: none;
}
.custom-switch-xs{
  padding: 3px 2px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  /* text-align: center; */
}
#accordion .custom-switch-xs{
   text-align: left; 
}
#accordion h4{
   width: 90%; 
}
#accordion .btn{
   width: 10%; 
}
#accordion .custom-switch-xs span{
   line-height: 25px;
   font-size: 14px;
}
.img-icon{
  width: 50px;
}
.shadow-none{
  box-shadow: none;
}
.custom-switch .custom-switch-input+.custom-switch-btn{
  background-color: #f11400;
}
button.close{
  position: absolute;
    padding: 0px !important;
    right: 20px;
}
.card-header .custom-switch-xs{
font-size: 16px;
}
#myModal .modal-content{
  background: rgba(0, 0, 0, 0.5);
}