@import url(https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-semibold {
  font-weight: 600;
  font-family: GreycliffCF!important;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem!important;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.bg-rebel-green {
  --tw-bg-opacity: 1;
  background-color:#35e3e3;
  text-shadow:none;
}
.round-lg {
  border-radius: .5rem!important;
  border: none;
}
.text-black{
  color: black!important;
}
.hoverbg-green-400:hover{
  background-color: #34d399;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 2rem!important;
}
.pollsheader{
  font-family:GreycliffCF!important;
  font-weight: 400;
  line-height: 5px;
}
.p-5 {
  padding: 1.25rem!important;
}
.bg-gray-900 {
  background-color: rgba(17,24,39,var(--tw-bg-opacity));
}
.border-rebel-button {
  --tw-border-opacity: 1;
  border-color: #794284!important;
}
.border {
  border-width: 1px!important;
}
.rounded-lg {
  border-radius: .5rem!important;
}
.cursor-pointer {
  cursor: pointer;
}
.w-full {
  width: 100%;
}
.mb-2 {
  margin-bottom: .5rem!important;
}
.font-GreycliffCF{
  font-family: GreycliffCF;
}
.font-700{
  font-weight: 700;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-red-400{
  color: #f87171;
}
.lg\:text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.fixed {
  position: fixed;
}
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.z-10 {
  z-index: 10;
}
.overflow-auto {
  overflow: auto;
}
.bg-opacity-50 {
  opacity: 0.5;
}
.inset-y-0 {
  top: 0;
  bottom: 0;
}
.inset-x-0 {
  left: 0;
  right: 0;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.md\:w-2\/12 {
  width: 16.666667%;
}
.mt-48 {
  margin-top: 12rem;
}
.eDokRY {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
small {
  font-size: .875em;
}
.eDokRY .partition {
  font-size: 42px;
}
.ftnCKg {
  font-size: 24px;
  line-height: 29px;
  padding: 26px 40px;
  border-radius: 46px;
  border: 0px;
  background: linear-gradient(rgb(126, 39, 208) 0.01%,
   rgb(66, 11, 118) 100%);
}
.time{
  font-family: Gotham Rounded,sans-serif;
  font-weight: 400;
  font-size: 42px;
}
.teamheading{
  font-weight:700 ;
  font-family: Poppins;
  color: white;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 30px;
}
#connectModal .description {
  font-size: 18px;
}
#connectModal .title {
  font-size: 24px;
}
#connectModal .list-group-item {
  padding: 5px;
  border: none;
}
.pointer{
  cursor: pointer;
}

#afooter{
  font-size:32px;
  overflow: hidden; 
  display: inline-block;
  width: 65px;
  padding-top: 14px;
  padding-bottom: 14px;
  border: 1px solid black;
  background-color: black;
  border-radius:50%!important;
  color: #35e3e3;
}
.footerp{
  font-weight: 400;
  font-size: 16px;
}
.flex-col {
  flex-direction: column;
}
.bg-rebel-button {
  --tw-bg-opacity: 1;
  background-color: rgba(121,66,132,var(--tw-bg-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156,163,175,var(--tw-text-opacity));
}
.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}
.block {
  display: block;
}
svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.font-bold {
  font-weight: 700;
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229,231,235,var(--tw-text-opacity));
}
.gap-2 {
  grid-gap: .5rem;
  gap: .5rem;
}
.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}
.border-rebel-green {
  --tw-border-opacity: 1;
  border-color: rgba(53,227,227,var(--tw-border-opacity))!important;
}
.disabled\:opacity-50:disabled {
  opacity: .5;
}
.bg-rebel-black {
  --tw-bg-opacity: 1;
  background-color: rgba(14,14,14,var(--tw-bg-opacity));
}
.min-h-screen {
  min-height: 100vh;
}
.pollbutton{
  color: #7f7a7a;
}

/*.overlay{
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: red;
}*/
.align-items-center {
    align-items: center!important;
}
.justify-content-center {
    justify-content: center!important;
}
.d-flex {
    display: flex!important;
}
.overlay{
  height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: #1a1a1a;
}


.sidebar-wrapper{
  top: 50px;
}


.smoke span {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    color: white;
    color: #fff;
    font-size: 35px;
    font-weight: 900;
    font-family: 'Finger Paint', cursive;
}
.bg-transparent{
  background-color: transparent !important;
}
.p-absolute{
  position: absolute;
}
.opacity{
  background-color: rgba(0, 0, 0, 0.5);
}
.logo{
 font-size: 35px;
    font-weight: 900;
    font-family: 'Finger Paint', cursive;
    color: #000; 
    text-decoration: none;
    padding-top: 10px;
}

.smoke span {
  display: inline-block;
  text-shadow: 0 0 0 whitesmoke;
  animation: smoky 5s 3s both;
}

.smoke span:nth-child(even){
  animation-name: smoky-mirror;
}

@keyframes smoky {
  60% {
    text-shadow: 0 0 40px whitesmoke;
  }
  to {
    transform:
      translate3d(15rem,-8rem,0)
      rotate(-40deg)
      skewX(70deg)
      scale(1.5);
    text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}

@keyframes smoky-mirror {
  60% {
    text-shadow: 0 0 40px whitesmoke; }
  to {
    transform:
      translate3d(18rem,-8rem,0)
      rotate(-40deg) 
      skewX(-70deg)
      scale(2);
     text-shadow: 0 0 20px whitesmoke;
    opacity: 0;
  }
}


.modal-backdrop {
   background-color: transparent;
}
.card-body{
	font-family: 'Roboto Mono', monospace;
}
.modal-logo{
  font-family: 'Finger Paint', cursive;
  color: #fff;
  font-size: 25px;
}
.card-body p{
	font-size: 14px;
}
.footer-link{
	list-style: none;
}
.card{
	    box-shadow: 8px 8px 0 0 #222326;
    border: 1px solid #222326;
    border-radius: 10px;
    border-radius: 5px;
    background: #fff
}

.cst_transparent{opacity:  0.85;}
.cst_transparent_home{opacity:  0.75;}

#countdown {
  position: relative;
  margin: 0px .5rem;
  height: 20px;
  width: 20px;
  text-align: center;
  float: left;
}
#countdown-number {
  color: #000;
  display: inline-block;
  line-height: 20px;
  font-weight: 700;
  font-size: 12px;
}
#countdown svg {
  position: absolute;
  top: 5px;
  right: 0;
  width: 20px;
  height: 20px;
  overflow: visible;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

#countdown svg circle {
  stroke-dasharray: 50px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #000;
  fill: none;
}

.btn-transparent{
  background-color: transparent;
  border: none;
}
.btn-transparent i{
  font-size: 20px;
  font-weight: 100;
}


#myVideo {margin-bottom:  10px !important;}

#wavybg-wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
}
.custom-switch label{
  vertical-align: text-top;
}
.tool-tip {
  display: inline-block;
}

.tool-tip [disabled] {
  pointer-events: none;
}
.custom-switch-xs{
  padding: 3px 2px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  /* text-align: center; */
}
#accordion .custom-switch-xs{
   text-align: left; 
}
#accordion h4{
   width: 90%; 
}
#accordion .btn{
   width: 10%; 
}
#accordion .custom-switch-xs span{
   line-height: 25px;
   font-size: 14px;
}
.img-icon{
  width: 50px;
}
.shadow-none{
  box-shadow: none;
}
.custom-switch .custom-switch-input+.custom-switch-btn{
  background-color: #f11400;
}
button.close{
  position: absolute;
    padding: 0px !important;
    right: 20px;
}
.card-header .custom-switch-xs{
font-size: 16px;
}
#myModal .modal-content{
  background: rgba(0, 0, 0, 0.5);
}
